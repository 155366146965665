@import "../../assets/scss/style.scss";
.tutorial-banner-content {
  display: flex;
  .back-link {
    flex: 0 0 auto;
    width: 23.5%;
    padding: 0 1.5rem;
  }
  .toggle-wrapper-outer {
    display: flex;
    flex: 0 0 auto;
    width: 76.5%;
    padding: 0 1.5rem;
    .toggle-sidebar {
      display: none;
    }
    h2 {
      font-size: 25px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 22px;
    }
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
}
@include media-breakpoint-down(xl) {
  .tutorial-main .nav{
    position: relative;
    z-index: 99;
  }
  .tutorial-main .tutorial-banner {
    position: relative;
    z-index: 99;
  }
  .tutorial-banner-content {
    .back-link {
      padding: 0;
      width: 10%;
    }
    .toggle-wrapper-outer {
      padding: 0;
      width: 90%;
      h2 {
        font-size: 25px;
        @include media-breakpoint-down(lg) {
          font-size: 22px;
        }
        @include media-breakpoint-down(md) {
          font-size: 20px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1025px) {
  // .tutorial-banner {
  //     margin-top: 20px;
  // }
  .tutorial-banner-content {
    h2 {
      font-size: 1.5rem;
    }
    .back-link,
    .course-tab {
      display: none;
    }
    .toggle-wrapper-outer {
      background-color: theme-colors(primary);
      color: theme-colors(white);
      width: 100%;
      .toggle-sidebar {
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .tutorial-banner {
    margin-top: 20px;
  }
}

.course-tab {
  &.nav-pills {
    .nav-link {
      color: theme-colors(dark);
      &.active,
      &:hover {
        color: theme-colors(secondary);
        background: transparent;
      }
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        padding: 3px 8px 3px 5px;
      }
    }
    li {
      position: relative;
      margin: 0;
      &:last-child {
        &::after {
          content: none;
        }
      }
      &::after {
        content: "|";
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}

// @include media-breakpoint-down(xl)
// {
//     .toggle-wrapper
//     {
//         background-color: theme-colors(primary);
//         color: theme-colors(white);
//     }
// }
.footer-sm.tutorial-footer {
  a {
    padding: 1rem 0rem;
    display: inline-block;
    position: relative;
    &.active-link::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0px;
      bottom: 0px;
      background: theme-colors(white);
    }
  }
  .container {
    padding: 0rem 0.9375rem;
  }
}
