.card-container {

    &:hover {
        transform: scale(1.01);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
}

li.nav-item-custom {
    margin-right: 1px;
}

li:nth-child(2).nav-item-custom { 

    a {
        display: flex;
        align-items: center;

        img {
            width: 20px;
            margin-right: 5px;
        }
    }
}

li:nth-child(3).nav-item-custom {

    background-color: #b8f397 !important;
    border-radius: 0.375rem 0.375rem 0rem 0rem;

    a {
        color: #072100 !important;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            margin-right: 5px;
        }
    }

    a.active {
        background-color: #b8f397 !important;
        border-bottom: 2px solid #072100;
        font-weight: 600;
    }
}


li:nth-child(4).nav-item-custom {

    background-color: #ffdad6 !important;
    border-radius: 0.375rem 0.375rem 0rem 0rem;

    a {
        color: #410002 !important;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            margin-right: 5px;
        }
    }
    a.active {
        background-color: #ffdad6 !important;
        border-bottom: 2px solid #410002;
        font-weight: 600;
    }
}