@import "../../assets/scss/style.scss";

.slick-dots {
    bottom: 0px !important;
    position: static !important;
}

button.slick-arrow {
    &::before {
        opacity: 1;
        color: #000;
        background-repeat: no-repeat;
        content: "";
        width: 1.875rem;
        height: 1.875rem;
        position: absolute;

        @include media-breakpoint-down(xl) {
            background-size: 20px;
        }
    }

    &.slick-prev {
        
        &::before {
            background-image: url(../../assets/images/arrow-dropleft.svg) !important;
            left: 0;
            background-position: left center;
            transition: 0.3s all;
        }

        &:hover::before {
            left: -5px;
            transition: 0.3s all;
        }

        &.slick-disabled {
            pointer-events: all;
            cursor: no-drop;

            &:hover:before {
                left: 0;
            }
        }
    }

    &.slick-next {
       
        &::before {
            background-image: url(../../assets/images/arrow-dropright.svg) !important;
            right: 0;
            background-position: right center;
            transition: 0.3s all;
        }

        &:hover::before {
            right: -5px;
            transition: 0.3s all;
        }

        &.slick-disabled {
            pointer-events: all;
            cursor: no-drop;

            &:hover:before {
                right: 0;
            }
        }
    }
}