@import "./App.scss";

// Component scss
@import "./components/cards/coursecard.scss";
@import "./components/coursedatails/coursedatails.scss";
@import "./components/dashboard/dashboard.scss";
@import "./components/footer/footer.scss";
@import "./components/forms/form.scss";
// @import "./components/header/header.scss";
@import "./components/navbar/navbar.scss";
// @import "./components/userdashboard/userdashboard.scss";

// // Pages scss

@import "./pages/admin/admin.scss";
// @import "./pages/course/course.scss";
// @import "./pages/dashboardhome/dashboardhome.scss";
@import "./pages/description/description.scss";
@import "./pages/home/home.scss";
@import "./pages/login/login.scss";
// @import "./pages/trainingbatch/trainingbatch.scss";
@import "./pages/userdashboard/userdashboard.scss";

// ui/ux

@import "./assets/scss/style.scss";
@import "./assets/scss/function";


::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}