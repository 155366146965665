.discription {
    h4 {
        font-weight: 600;
      }
      .dataframe {
        position: relative;
        border-radius: 0.2rem;
        box-shadow: 0 0 10px #554a4a33;
      }
}
