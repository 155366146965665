@import "../../assets/scss/style.scss";
.res-img {
    height: 10.875rem;
    background: theme-colors(dark-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
        height: 8rem;
        img {
            width: 2.875rem;
            height: auto;
        }
    }
}
.res-header {
    @include media-breakpoint-down(md) {
        img {
            width: 1.5rem;
            height: auto;
        }
    }
}
.res-item-inner {
    border: 1px solid theme-colors(dark-gray);
    margin-bottom: 1.875rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
}