.batchinfo-list {
  overflow-y: auto;
  li {
    white-space: nowrap;
    font-size: 16px !important;
    &:not(:last-child) {
      margin-right: 3rem;
    }
  }
}
::-webkit-scrollbar {
  width: 2px;
}
.accordion-body .nav-tabs .nav-link.active {
  color: #005ba0;
  border-color: #005ba0;
}
.w-90px {
  width: 80px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}