@use "sass:map";
//RFS CONFIG
$rfs-base-value: 1rem;
//import RFS
@import "~rfs/scss";

// Font-family
$family-regular: "Poppins", sans-serif;

// color
$theme-colors: (
  primary: #005ba0,
  secondary: #0091ff,
  success: #04a429,
  white: #ffffff,
  dark: #242424,
  info: #7cceff,
  light: #e5e5e5,
  color-lblue: #def4ff,
  black: #000000,
  dark-gray: #666666,
  gray: #999999,
  light-gray: #f5f5f5,
  green: #31bbae,
  light-green: #97ddd6,
  light-background: #f8f1f1,
  footer: #f0f0f0,
  border-color: #e0e0e0,
  page-bg: #fbfbfb,
  color-dblue: #19456b,
  color-admin-lblue: #11698e,
  color-mblue: #1792c7,
  color-dgreen: #062019,
  color-dback: #554a4a,
  color-llblue: #f7f9ff,
  red: #ff0000,
);

$font-16: 1rem;
$font-14: 0.875rem;
$font-12: 0.75rem;
$font-10: 0.625rem;

// Font-size
$theme-fonts: (
  font-36: rfs-fluid-value(2.25rem),
  font-32: rfs-fluid-value(2rem),
  font-24: rfs-fluid-value(1.5rem),
  font-20: rfs-fluid-value(1.25rem),
  font-18: rfs-fluid-value(1.125rem),
  font-16: $font-16,
  font-14: $font-14,
  font-12: $font-12,
  font-10: $font-10,
);
// @debug map.set($theme-fonts, "font-16", "14px");
// @debug map.set($theme-fonts, "font-14", "12px");

// Font-weight
$theme-font-weight: (
  font-weight-700: 700,
  font-weight-600: 600,
  font-weight-500: 500,
  font-weight-400: 400,
  font-weight-300: 300,
);

$border-radius: 0.625rem;

::-webkit-progress-bar {
  height: 1px;
}
