@import "../../assets/scss/style.scss";
.accordion-content {
  .accordion-item {
    border: 0px;
    margin-top: 1.25rem;
    @include media-breakpoint-down(lg) {
      margin-top: 1rem;
    }
    color: theme-colors(dark-gray);
    background: transparent;
    .accordion-header {
      border-radius: 0 !important;
    }
    .accordion-body {
      padding: 1rem;
    }
    .accordion-button {
      color: theme-colors(dark-gray);
      position: relative;
      font-weight: 500;
      //font-size: theme-fonts(font-24);
      border: 1px solid theme-colors(light);
      border-radius: 0;
      margin-bottom: 0px !important;
      padding: 0.5rem 1rem !important;
      background-color: #ffffff;
      -webkit-appearance: none;
      &::after {
        width: 1rem;
        height: 0.5625rem;
        background-size: 1rem;
        background-image: url("../../assets/images/arrow-down.svg");
        transform: translate(-50%, -50%) rotateZ(0deg);
        position: absolute;
        right: 1.5rem;
        top: 50%;
        @include media-breakpoint-down(md) {
          right: 1rem;
        }
      }
      &:focus {
        box-shadow: none;
      }
      &:not(.collapsed) {
        color: theme-colors(dark-gray);
        background-color: transparent;
        border: 1px solid transparent;
        box-shadow: none;
        border-bottom: 1px solid theme-colors(gray);
        border-radius: 0;
        padding: 0.5rem 1rem !important;
        &::after {
          transform: translate(-50%, -50%) rotateZ(180deg);
        }
        &:focus {
          box-shadow: inset 0 -1px 0 theme-colors(light);
        }
      }
    }
  }
  .accordion-body {
    @include media-breakpoint-down(md) {
      padding: 0.5rem;
    }
    p:last-child {
      margin-bottom: 0px;
    }
  }
}

.course-detail-accordion {
  word-break: break-all;
  .accordion-body {
    ul {
      padding: 0px;
      ul {
        > li {
          &::before {
            content: "\26AC";
            left: 2.5rem;
            color: theme-colors(info);
            font-size: 21px;
            line-height: 21px;
            @include media-breakpoint-down(md) {
              left: 2rem;
            }
          }

          padding-left: 4.5rem;
          @include media-breakpoint-down(md) {
            padding-left: 3.5rem;
          }
        }
      }
      li {
        position: relative;
        padding-left: 2.5rem;
        list-style-type: none;
        @include media-breakpoint-down(md) {
          padding-left: 2rem;
        }
        &::marker {
          content: none;
        }
        &::before {
          position: absolute;
          left: 0rem;
          content: url("../../assets/images/li-icon.svg");
          width: 10px;
          height: 10px;
        }

        p {
          line-height: 1.5;
          word-break: break-word;
          &:last-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
    .alert {
      word-break: break-word;
      @include media-breakpoint-down(md) {
        font-size: theme-fonts(font-14);
      }
    }
  }
  h6 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
  }
  p {
    color: theme-colors(dark-gray);
    line-height: 2;
  }
  strong,
  b {
    font-weight: 500;
  }
  a {
    color: theme-colors(primary);
    text-decoration: none;
    margin-bottom: 1rem;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
}

.sidebar {
  .accordion-content {
    &.course-detail-accordion.tutorial-content {
      .accordion-item {
        .accordion-button {
          &:not(.collapsed) {
            color: #666666;
            background-color: #f2f2f2;
            border-bottom: 1px solid #bbb;
          }
          &::after {
            content: none;
          }
        }
      }
    }
    .accordion-item {
      border-bottom: 1px solid theme-colors(light);
      background-color: transparent;
      margin-top: 0px;
      margin-bottom: 0;
      @include media-breakpoint-down(xl) {
        // border: 2px solid theme-colors(light);
      }
      &:last-child {
        margin-bottom: 0px;
      }
      @include media-breakpoint-down(xl) {
        margin-bottom: 0;
        border-bottom: 1px solid theme-colors(light);
        border-radius: 0;
      }
      .accordion-button {
        border: 0px;
        padding: 0.75rem 1rem !important;
        padding-right: 2rem !important;
        font-size: 1rem;
        line-height: 1.5;
        word-break: break-word;
        &::after {
          right: 0.5rem;
          width: 0.625rem;
          height: 0.375rem;
          background-size: 0.625rem;
          top: 1.25rem;
        }
        &:not(.collapsed)[aria-expanded="true"] {
          color: theme-colors(dark-gray);
          background-color: #f2f2f2;
          border-bottom: 1px solid #bbb;
          &::after {
            transform: translate(-50%, -50%) rotateZ(180deg);
          }
        }
        &:not(.collapsed) {
          &::after {
            transform: translate(-50%, -50%) rotateZ(0deg);
          }
        }
        @include media-breakpoint-down(xl) {
          color: theme-colors(dark);
          font-size: 1rem;
        }
      }
      .accordion-collapse {
        &.collapse {
          background: #f2f2f2;
        }
      }
      .accordion-body {
        ul {
          margin-bottom: 0;
          li {
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.play {
  content: url("../../assets/images/gray-circle.svg");
}
.actives {
  content: url("../../assets/images/blue-circle.svg");
  // filter: ;
}
.notplay {
  content: url("../../assets/images/confirm.svg");
}
