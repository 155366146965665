.shortNoteHeading {
    font-size: 28px;
    color: #000000;
    margin: 0;
}
.shortnotes {
    word-break: normal;
    h3 {
        font-size: 18px;
        color: #3d3d3d;
        border-top: 1px solid #dddddd;
        margin-top: 20px;
        padding-top: 10px;
    }
    h4 {
        font-size: 16px;
        color: #333333;
        margin-bottom: 8px;
    }
    p {
        font-size: 15px;
        line-height: 24px;
        strong {
            color: #005ba0;
        }
    }
    code {
        font-size: 14px;
    }
    pre {
        margin-top: 8px;
        background-color: #000000;
        padding: 12px 20px;
        border-radius: 5px;
        cursor: pointer;
        code {
            color: #e2e2e2;
            font-size: 14px;
        }
    }
    ul, ol {
        margin: 0;
        li {
            font-size: 15px;
            margin-bottom: 10px;
            strong {
                color: #005ba0;
            }
            a {
                margin: 0;
            }
            p {
                margin: 0 !important;
            }
        }
    }
}