@import "../../assets/scss/style.scss";

.card.mylearn-card
    {
        &.subscribe-card{
            background: #f0f0f0;
            border-color: #04A429;
          }
    .card-body
    {
        .card-text {
            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }
        .course-progressbar
        {
            overflow: hidden;
            max-width: 36rem;
            width: 100%;
            height: 0.3125rem;
        }
        a
        {
            text-decoration: none;
            &:hover
            {
                text-decoration: underline;
            }
        }
    }
}