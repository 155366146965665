@import "../../assets/scss/style.scss";
.four-col-item {
    .card{
        transition: 1s all ease;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 0px 14px #00000029;
        }
        &.subscribe-card{
            background: #f0f0f0;
            border-color: #04A429;
          }
    }
.tech-details {
    span {
        font-size:theme-fonts(font-14);
        margin-top: 0.625rem;
        color: theme-colors(dark-gray);
        @include media-breakpoint-down(md) { 
            font-size:theme-fonts(font-12);
            margin-top: 0.3125rem;
        }
    }
}
.tech-img {
    height: 11.25rem;
    @include media-breakpoint-down(md) { 
        height: 10rem;
    }
    background: theme-colors(light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 150px;
        object-fit: contain;
        height: 70px;
        @include media-breakpoint-down(md) { 
            max-width: 94px;
        }
        @include media-breakpoint-down(sm) { 
            max-width: 60px;
        }
    }
}
}
