@import "../../assets/scss/style.scss";
.learn-course
{
    .slick-slider
    {
        .slick-list
        {
            margin: 0 -1.5rem;
            @include media-breakpoint-down(xl)
            {
                margin: 0 -1rem;
            }
            @include media-breakpoint-down(lg)
            {
                margin: 0 -0.625rem;
            }
            .learncourse-slider
            {
                padding: 1.5rem 0.75rem 0;width: 100%;
                @include media-breakpoint-down(xl)
                {
                    padding: 1rem 0.75rem 0;
                }
                @include media-breakpoint-down(lg)
                {
                    padding: 0.5rem 0.75rem 0;
                }
                .card
                {
                    @include rounded(0.3125rem);
                    border: 1px solid theme-colors(border-color);
                    transition: 0.5s all;
                    &:hover{
                        box-shadow: 0px 0px 15px #00000029;
                    }
                    &.subscribe-card{
                        background: #f0f0f0;
                        border-color: #04A429;
                      }
                    .course-item-img {
                        height: 11.25rem;
                        @include media-breakpoint-down(md) { 
                            height: 10rem;
                        }
                        background: theme-colors(light-gray);
                        display: flex;
                        align-items: center;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        justify-content: center;
                        padding: 0.75rem;
                        img {
                            width: 100%;
                            object-fit: contain;
                            width: 70px;
                            height: auto;
                            @include media-breakpoint-down(md) {
                                // height: 40%;
                            }
                        }
                    }
                    .card-body
                    {
                        padding: 0;
                        .card-text
                        {
                            line-height: 1.5rem;             
                        }
                        .course-details 
                        {   
                            .course-item
                            {
                                border-bottom: 1px solid theme-colors(border-color);   
                            }
                            a
                            {
                                text-decoration: none;
                                &:hover
                                {
                                    text-decoration: underline;
                                    @include media-breakpoint-down(md)
                                    {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        button.slick-arrow
        {
            &::before
            {
                opacity: 1;
                color: #000;
                background-repeat: no-repeat;
                content: "";
                width: 1.875rem;
                height: 1.875rem;
                position: absolute;
                @include media-breakpoint-down(xl)
                {
                   background-size: 20px;
                }
            }
            &.slick-prev
            {
                left: -3.4375rem;
                @include media-breakpoint-down(xl)
                {
                    left: -1.875rem;
                }
                &::before {
                    background-image: url(../../assets/images/arrow-dropleft.svg) !important;
                    left: 0;
                    background-position: left center;
                    transition: 0.3s all;
                }
                &:hover::before
                {
                    left: -5px;
                    transition: 0.3s all;
                }
                &.slick-disabled
                {
                    pointer-events: all;
                    cursor: no-drop;
                    &:hover:before {
                        left: 0;
                    }
                }
            }
            &.slick-next
            {
                right: -3.4375rem;
                @include media-breakpoint-down(xl)
                {
                    right: -1.875rem;
                }
                &::before {
                    background-image: url(../../assets/images/arrow-dropright.svg) !important;
                    right: 0;
                    background-position: right center;
                    transition: 0.3s all;
                }
                &:hover::before
                {
                    right: -5px;
                    transition: 0.3s all;
                }
                &.slick-disabled
                {
                    pointer-events: all;
                    cursor: no-drop;
                    &:hover:before {
                        right: 0;
                    }
                }
            }
        }
    }
   
    
}
