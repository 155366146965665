@import "../../assets/scss/style.scss";
.video-content {
    border: 1px solid theme-colors(light);
    background: theme-colors(white);
    word-break: break-all;
    padding: 1rem;
    margin-top: 1;
    @include media-breakpoint-up(xl) {
    padding: 1.875rem;
    margin-top: 1.875rem;
    } 
}
@media only screen and (max-width: 1025px) { 
    .sidebar-course-content {
        width: 100% !important;
    }
}
@media only screen and (max-width: 1199px) {
    .sidebar-wrapper {
        padding-top: 0rem;
    }
}