.clr4 {
  background: #004ba1;
}
h4 {
  font-weight: 600;
}
.dataframe {
  position: relative;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px #554a4a33;
}

.learning-card-wrapper
{
  .card {
    position: relative;
    height: 100%;
    & .row {
      height: 100%;
    }
    &.subscribe-card{
      background: #f0f0f0;
      border-color: #04A429;
    }
  }
}

.card .mask {
  background-color: white;
  visibility: hidden;
  width: 120px;
  text-align: justify;
  font-size: small;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  height: 0px;
  top: 55px;
  overflow-y: scroll;
  transition: height 1s;  
}
.card .mask::after {
  content: "";
  position: absolute;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card .mask::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.card .mask {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card:hover .mask {
  visibility: visible;
  padding-left: 23px;
  padding-right: 23px;
  border-top: white;
  height: 260px;
  box-shadow: -2px 4px 5px #554a4a33, 2px 4px 5px #554a4a33;
}
