
#div {
  background: #c2c2c2;
}
.href-link:hover {
  background: #16c79a !important;
}
.href-link:focus {
  background: #16c79a !important;
}
.href-link:active {
  background: #16c79a !important;
}

.bg {
  background: linear-gradient(90deg, #16c79a, #01fda9);
}
.navbar-toggler:focus {
  box-shadow: 0 0 4px #ffffff;
}
.collapse {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 0.9rem;
        font-weight: 500;
        text-transform: capitalize;
        color: #11698e;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: all 0.3s linear;
        &:hover {
          color: theme-colors(green);
        }
      }
      .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
        width: 1.5rem;
        font-size: 12px;
      }
      .notification {
        position: relative;
      }
    }
  }
}
.leftbar {
  padding-left: 5px;
}

#trainingplancol {
  border-right: 2px solid black;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 0px;
    right: 30px;
    padding: 0px;
  }
}
.dataframe {
  position: relative;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px #554a4a33;
  .row {
    .frame {
      background: #ffffff;
      height: 510px;
      width: 810px;
      border-radius: 0.5rem;
      display: flex;
      position: relative;
      figure {
        video {
          box-shadow: 0 0 10px #554a4a6c;
          height: 490px;
          width: 800px;
        }
      }
      .close {
        position: absolute;
        height: 2rem;
        width: 2rem;
        right: -1rem;
        top: -1rem;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 10px #554a4a6c;
        border-radius: 50%;
        color: theme-colors(green);
        &:hover {
          background: theme-colors(border-color);
        }
      }
    }
  }
  // }
  #accordionVideos {
    .accordion-item {
      box-shadow: 0 0 10px #554a4a33;
      .accordion-header {
        .accordion-button {
          &:focus {
            box-shadow: none;
          }
          &:not(.collapsed) {
            background: none;
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          .playbtn {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            cursor: pointer;
          }
          .bg {
            background: linear-gradient(90deg, #16c79a, #01fda9);
          }
        }
      }
    }
  }
  .day {
    font-weight: 600;
  }
  .wtl {
    font-weight: 600;
  }
  .wtllist {
    list-style: none;
    padding: 0;
    li {
      font-size: 0.9rem;
      color: theme-colors(color-dback);
    }
  }
  .ppts {
    .ppt {
      .card {
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
        box-shadow: 0 0 10px #554a4a33;
        padding: 0.1rem;
        overflow: hidden;
        &:hover {
          border: 1px solid #ffffff;
        }
      }
      .card * {
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
      }
    }
  }
  .discussback {
    position: fixed;
    background: #00000077;
    height: 99vh;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .row {
      width: 50%;
      .frame {
        background: #ffffff;
        border-radius: 0.5rem;
        width: 100%;
      }
      .close {
        cursor: pointer;
      }
    }
  }
  .discussion-card {
    .clr1 {
      background: #ff6c6c;
    }
    .clr2 {
      background: #eeee42;
    }
    .clr3 {
      background: #7eff7e;
    }
    .clr4 {
      background: #5ce7e7;
    }
    .clr5 {
      background: #8080ff;
    }
    .clr6 {
      background: #ff7eff;
    }
    .clr7 {
      background: #ffbb7b;
    }
  }
}
@media only screen and (max-width: 992px) {
  #playvideo {
    width: 80%;
  }
  #trainingplancol {
    border-right: none;
  }
  .leftbar {
    padding-left: 0px;
    margin-left: 0px;
    .h6 {
      font-size: 15px;
    }
    p {
      font-size: 14px;
      padding-bottom: 0px;
      margin-bottom: 3px;
    }
    .videoblock {
      font-size: 14px;
      word-wrap: break-word;
    }
    .alert-warning {
      font-size: 14px;
    }
    .wtllist {
      font-size: 14px;
      word-wrap: break-word;
    }
  }
}
